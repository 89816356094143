import '../../scss/backend.scss';


import { register, render } from 'timeago.js';
import timeago_NL from 'timeago.js/lib/lang/nl'
register('nl', timeago_NL);

document.timeago = render;
/* LEGACY  START */
import $ from 'jquery';
// import bsCustomFileInput from 'bs-custom-file-input'

window.jQuery = $;
window.$ = $;
window.moment = require('moment');
// window.bsCustomFileInput = bsCustomFileInput;

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
require('bootstrap');


import('./scripts.js');

// start the Stimulus application
import '../bootstrap';
import feather from "feather-icons";

window.addEventListener('DOMContentLoaded', event => {
    // Activate feather
    feather.replace();
});
